import React from 'react'

import {
    linklist,
    linklist_title,
    linklist_links
} from './LinkList.module.css'

const LinkList = ( props ) => {
    return (
        <div className = {linklist}>
            <h2 className={linklist_title}>{props.title}</h2>
            <div className={linklist_links}>
                {props.children}
            </div>
        </div>
    )
}

export default LinkList
