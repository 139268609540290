import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LinkList from "../components/LinkList"
import { graphql } from "gatsby"

const Certifications = ({ data, location }) => {
  const lists = data.allContentfulCertifications.nodes

  return (
    <Layout location={location}>
      <Seo title="Certifications" />
      <div className="section-wrapper page-main">
        <h1 className="page-main-title">Certifications</h1>

        {lists.map(list => {
          return (
            <LinkList
              key={list.certificationName}
              title={list.certificationName}
            >
              {list.certifications.map(cert => {
                return (
                  <a key={cert.title} href={cert.file.url}>
                    {cert.title}
                  </a>
                )
              })}
            </LinkList>
          )
        })}
      </div>
    </Layout>
  )
}

export default Certifications

export const pageQuery = graphql`
  query CertificationsContent {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCertifications {
      nodes {
        certificationName
        certifications {
          title
          file {
            url
          }
        }
      }
    }
  }
`
